import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import useRecentAvgs from "@/game-eft/utils/use-recent-avgs.mjs";
import AccoladeHeadshotIcon from "@/inline-assets/accolade-crosshair.svg";
import {
  Accolade as AccoladeShared,
  AccoladeHeader as AccoladeHeaderShared,
  DataBars,
} from "@/shared/Accolades.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import { calcRate, sanitizeNumber, toFixedNumber } from "@/util/helpers.mjs";
import { formatToPercent } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function MatchAccolades() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    parameters: [profileId, gameId],
  } = useRoute();
  const state = useSnapshot(readState);
  const { count, recentAvgs } = useRecentAvgs(profileId, gameId);
  const $match = state.eft.matches[gameId];
  const match = useEvalState($match);
  const player = match?.playerMatches.find(
    (i) => i.player.accountId === profileId,
  );
  const playerKills = sanitizeNumber(player?.killsBody + player?.killsHead);
  const recentAvgKills = sanitizeNumber(
    recentAvgs?.killsBody + recentAvgs?.killsHead,
  );
  const didBetterKills = playerKills >= recentAvgKills;
  const playerKillsRate =
    playerKills && recentAvgKills
      ? calcRate(playerKills, recentAvgKills)
      : Math.max(playerKills, recentAvgKills ?? 0) + 1;
  const playerKillsPercentile = formatToPercent(
    language,
    Math.abs(didBetterKills ? playerKillsRate - 1 : 1 - playerKillsRate),
    { max: 1 },
  );
  return (
    <AccoladeShared
      // @ts-ignore This shared component needs to be refactored to work properly
      icon={<AccoladeHeadshotIcon />}
      // @ts-ignore This shared component needs to be refactored to work properly
      didBetter={didBetterKills}
      defaultShow
    >
      <AccoladeHeaderShared
        title={t("common:elims", "Elims")}
        // @ts-ignore This shared component needs to be refactored to work properly
        info={
          <Trans
            t={t}
            i18nKey={
              didBetterKills
                ? "fps:accolades.elimsBetter"
                : "fps:accolades.elimsWorse"
            }
            defaults={
              didBetterKills
                ? "Your elims are <highlight>{{value}}</highlight> better than your {{queue}} recent {{matches}} avg."
                : "Your elims are <highlight>{{value}}</highlight> worse than your {{queue}} recent {{matches}} avg."
            }
            values={{
              value: playerKillsPercentile,
              queue: match?.gameMode,
              matches: count,
            }}
            components={{ highlight: <span /> }}
          />
        }
        handleClick={() => {}}
      />
      <DataBars
        title={undefined}
        data={[playerKills, toFixedNumber(recentAvgKills, 1)]}
        legend1={t("common:thisMatch", "This Match")}
        legend2={t("common:stats.recentAvg", "Recent Avg.")}
        // @ts-ignore This shared component needs to be refactored to work properly
        didBetter={didBetterKills}
      />
    </AccoladeShared>
  );
}
