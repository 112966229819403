import React from "react";

import MatchTileScoreboard from "@/game-eft/components/MatchTileScoreboard.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

export default function Main() {
  const {
    parameters: [profileId, gameId],
  } = useRoute();
  return <MatchTileScoreboard gameId={gameId} profileId={profileId} />;
}
