import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import MatchTileWeapons from "@/game-eft/components/MatchTileWeapons.jsx";
import { BodyPartBE } from "@/game-eft/constants/constants.mjs";
import { useEvalState } from "@/util/eval-state.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Main() {
  const {
    parameters: [profileId, gameId],
  } = useRoute();
  const state = useSnapshot(readState);
  const $match = state.eft.matches[gameId];
  const match = useEvalState($match);
  const player = match?.playerMatches.find(
    (i) => i.player.accountId === profileId,
  );
  const weaponHash = Object.create(null) as {
    [weaponId: string]: {
      weapon: {
        id: string;
        longName: string;
        shortName: string;
        uuid: string;
      };
      kills: number;
      damage: number;
      hits: number;
      hitsHead: number;
    };
  };
  const weapons = Object.entries(
    player?.weaponHits.reduce((acc, cur) => {
      const wepId = cur.weapon.id;
      acc[wepId] = acc[wepId] ?? {
        weapon: cur.weapon,
        kills: 0,
        damage: 0,
        hits: 0,
        hitsHead: 0,
      };
      acc[wepId].kills += cur.kills;
      acc[wepId].damage += cur.damage;
      acc[wepId].hits += cur.hits;
      if (cur.bodyPart === BodyPartBE.HEAD) acc[wepId].hitsHead += cur.hits;
      return acc;
    }, weaponHash) ?? weaponHash,
  );
  const totalKills = weapons.reduce((acc, cur) => acc + cur[1].kills, 0);
  if (!weapons.length || !totalKills) return null;
  return <MatchTileWeapons gameId={gameId} profileId={profileId} />;
}
